.btn--search {
	// position: relative;
	// background-color: var(--color-ui);
	width: var(--ui-icon-size);
    height: var(--ui-icon-size);
}

// .btn--search .btn-inner {
//     position: relative;
// }

.btn--search .circles {
    display: block;
    position: relative;
    width: 90%;
    height: 90%;
}

.btn--search .circle {
    top: 48%;
    left: 48%;
    position: relative;
    position: absolute;
    transform-origin: center;
    background: var(--color-primary);
    transform: translate(-50%, -50%);
    border-radius: 50%;
}

.btn--search .circle--background {
    width: calc(100% - (var(--ui-icon-margin) * .8));
    height: calc(100% - (var(--ui-icon-margin) * .8));
    z-index: 2;
}

.btn--search .circle--foreground {
    width: calc(100% - (var(--ui-icon-margin) * 1.25));
    height: calc(100% - (var(--ui-icon-margin) * 1.25));
    background: var(--color-ui);
    z-index: 3;
}

.btn--search .line {
    display: inline-block;
    position: absolute;
    background: var(--color-primary);
    transform: translate(-50%, 0) rotate(45deg) !important; // overide .btn styles
    transform-origin: center;
    width: 30%;
    height: var(--ui-icon-height);
    top: 63%;
    left: 63%;
    right: 0;
    bottom: 0;
    z-index: 1;
}
