button {
    background-color: transparent;
    outline: none;
    border: none;
    margin: 0;
    padding: 0;
}

.btn {
    position: relative;
	display: inline-block;
    border-radius: 50%;
	color: var(--color-text);
	cursor: pointer;
}

.btn .btn-inner {
	position: relative;
	width: 100%;
	height: 100%;
}

.btn .line {
	position: absolute;
	background: var(--color-ui);
	border-radius: var(--ui-radius-l);
	transform: translate(-50%, -50%) rotate(0deg);
	transform-origin: center;
	width: calc(100% - var(--ui-icon-margin));
	height: var(--ui-icon-height);
	top: 50%;
	left: 50%;
}

.btn .line:nth-child(2) {
	transform: translate(-50%, -50%) rotate(-90deg);
}