.nav {
    display: block;
    display: flex;
    justify-content: center;
    position: fixed;
    transform: translateX(-50%);
    z-index: var(--zi-nav);
    padding: var(--margin-m);
	width: 100%;
    height: var(--ui-icon-height);
    left: 50%;
}

.nav-wrapper {
    display: flex;
}

.nav-wrapper,
.nav-item,
.btn-wrapper {
    height: 100%;
}

.nav-item,
.nav-faq .bar-wrapper {
    display: flex;
}

.btn-wrapper {
	width: var(--ui-icon-size);
    height: var(--ui-icon-size);
}

.btn-wrapper,
.bar-wrapper {
	margin-right: var(--margin-s);
}

.nav-faq .bar-wrapper {
    position: relative;
    background-color: var(--color-ui);
    border-radius: var(--ui-radius-l);
    padding: 1px var(--margin-m) 0;
    height: var(--ui-icon-size);
	width: 170px;
}

.nav-faq .bar-wrapper .btn-wrapper {
    position: absolute;
    background-color: var(--color-ui);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: var(--ui-radius-l);
    border-bottom-right-radius: var(--ui-radius-l);
    margin: 0 var(--margin-s) 0 0;
    // height: var(--ui-icon-size);
    top: 0;
    right: 0;
}

.nav-faq .bar-wrapper .js-clear-search .btn--close {
	background-color: var(--color-ui);
}

.nav-faq .bar-wrapper .js-clear-search .btn--close .line {
	background-color: var(--color-primary);
}

// .nav-faq .bar-wrapper .js-search-icon {
// 	display: none;
// }

.nav-item.nav-info {
    width: var(--ui-icon-size);
	min-width: var(--ui-icon-size);
}

@media screen and (min-width: $md-xs) {
	.nav-faq .bar-wrapper {
		width: 180px;
	}
}

@media screen and (min-width: $md-s) {
	.nav-faq .bar-wrapper {
		width: 190px;
	}
}

@media screen and (min-width: $md-m) {
	.nav {
		width: auto;
	} 

	.nav-faq .bar-wrapper {
		width: 300px;
	}
}
