.result__related {
	text-align: center;
	padding-bottom: var(--margin-m) !important;
	margin-top: var(--margin-xl);
}

.related-wrapper {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
}

.related {
	padding: var(--margin-s);
}

@media screen and (min-width: $md-m) {
	.result__related {
		width: 70%;
		margin: auto;
		padding: 6vw 0 !important;
	}

	.related-wrapper {
		flex-direction: row;
	}
}