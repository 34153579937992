:root {
	// Colors
	--color-mediumBlack: #494a4b;
	--color-black: rgb(116, 119, 121);
	--color-white: white;
	--color-grey: silver;
	--color-lightGrey: rgb(248, 248, 248);
	--color-mediumLightGrey: rgb(233, 233, 233);
	--color-mediumGrey: rgb(165, 165, 165);
	--color-clearBlack: rgba(2, 12, 19, 0.15);
	--color-clearGrey: rgba(215, 215, 215, 0.3);
	--color-clearWhite: rgba(250, 250, 250, 0.75);
	--color-ui-bg: var(--color-clearGrey);
	--color-text: var(--color-black);
	--color-hover: var(--color-text);
	--color-ui: var(--color-lightGrey);
	--color-primary: var(--color-black);
	// Margins
	--margin-xxl: 3rem;
	--margin-xl: 2rem;
	--margin-l: 1.5rem;
	--margin-m: 1rem;
	--margin-s: 0.5rem;
	--margin-xs: 0.25rem;
	// Z-index
	--zi-base: 0;
	--zi-alerts: 1;
	--zi-faq: 2;
	--zi-info: 3;
	--zi-nav: 4;
	// UI
	--ui-border-m: 1px solid var(--color-black);
	--ui-radius-xl: 5rem;
	--ui-radius-l: 2rem;
	--ui-radius-m: 1rem;
	--ui-icon-margin: 1.5rem;
	--ui-icon-size: 3rem;
	--ui-icon-height: 9%;
	// fonts
	--fs-xl: 1.6rem;
	--fs-l: 1.5rem;
	--fs-m: 1.25rem;
	--fs-s: 0.95rem;
	--fs-xs: 0.8rem;
	// animations
	--anim-timing: 1s;
	--anim-ease: linear; // easeInOutExpo
	// header
	--header-height: calc(var(--ui-icon-size) + (var(--margin-m) * 2));
}

/* iphone 6/7/8/X */
$md-xs: 375px;
/* iphone 6/7/8/plus */
$md-s: 414px;
/* tablet */
$md-m: 768px;
/* laptop */
$md-l: 992px;
/* screen */
$md-xl: 1200px;
/* large screen */
$md-xxl: 1400px;

@media screen and (min-width: $md-xs) {
	:root {
		--ui-icon-size: 3.5rem;
		--fs-m: 1.35rem;
	}
}

// @media screen and (min-width: $md-s) {
// 	:root {
// 	}
// }

@media screen and (min-width: $md-m) {
	:root {
		// UI
		--ui-icon-margin: 1.5rem;
		--ui-icon-size: 3.5rem;
		--fs-xl: 2rem;
		--fs-l: 3rem;
		--fs-m: 1.35rem;
		--fs-s: 1rem;
	}
}

@media screen and (min-width: $md-l) {
	:root {
		--fs-xl: 2.75rem;
		--fs-m: 1.25rem;
		--fs-s: 1.15rem;
	}
}

@media screen and (min-width: $md-xl) {
	:root {
		--fs-xl: 3.5rem;
		--fs-m: 1.35rem;
		--fs-s: 1.25rem;
	}
}

// @media screen and (min-width: $md-xxl) {
// 	:root {
// 		--fs-xl: 4rem;
// 	}
// }
