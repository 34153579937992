.btn--hint,
.btn--related {
	line-height: 1;
	transition-property: background-color, color;
	transition-duration: .25s, .20s;
	transition-timing-function: ease;
	// box-shadow: 1px 1px 6px 2px var(--color-clearBlack);
	box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
	background-color: var(--color-mediumLightGrey);
	border-radius: var(--ui-radius-xl);
	// color: var(--color-white);
	color: var(--color-text);
	padding: var(--margin-m) var(--margin-xl);
	padding-top: calc(var(--margin-m) + 2px);
	cursor: pointer;
}

@media (hover: hover) {
	.btn--hint:hover,
	.btn--related:hover {
		background-color: var(--color-text);
		color: var(--color-mediumLightGrey);
	}
}
