.plyr {
	--plyr-color-main: var(--color-primary);
	border-radius: var(--ui-radius-m);
}

.plyr__menu {
	display: none;
}

.plyr__progress input[type='range'],
.plyr__progress__buffer {
	width: 100%;
}

.plyr__progress__buffer {
	display: none;
}

.plyr--audio .plyr__control:focus-visible,
.plyr--audio .plyr__control:hover,
.plyr--audio .plyr__control[aria-expanded='true'] {
	background: inherit;
	color: inherit;
}
