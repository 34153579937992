/**
 *
 *  This file contains helpers classes.
 *
 *
 */
 
/**
 * Clear inner floats
 */
.clearfix::after {
    clear: both;
    content: "";
    display: table;
}

.is-hyphenized {
	hyphens: auto;
}

.is-hidden {
	display: none !important;
	visibility: hidden;
}

@media screen and (min-width: $md-m) {
	.is-hyphenized {
		hyphens: none;
	}
}