:root {
  --color-mediumBlack: #494a4b;
  --color-black: #747779;
  --color-white: white;
  --color-grey: silver;
  --color-lightGrey: #f8f8f8;
  --color-mediumLightGrey: #e9e9e9;
  --color-mediumGrey: #a5a5a5;
  --color-clearBlack: rgba(2, 12, 19, .15);
  --color-clearGrey: rgba(215, 215, 215, .3);
  --color-clearWhite: rgba(250, 250, 250, .75);
  --color-ui-bg: var(--color-clearGrey);
  --color-text: var(--color-black);
  --color-hover: var(--color-text);
  --color-ui: var(--color-lightGrey);
  --color-primary: var(--color-black);
  --margin-xxl: 3rem;
  --margin-xl: 2rem;
  --margin-l: 1.5rem;
  --margin-m: 1rem;
  --margin-s: .5rem;
  --margin-xs: .25rem;
  --zi-base: 0;
  --zi-alerts: 1;
  --zi-faq: 2;
  --zi-info: 3;
  --zi-nav: 4;
  --ui-border-m: 1px solid var(--color-black);
  --ui-radius-xl: 5rem;
  --ui-radius-l: 2rem;
  --ui-radius-m: 1rem;
  --ui-icon-margin: 1.5rem;
  --ui-icon-size: 3rem;
  --ui-icon-height: 9%;
  --fs-xl: 1.6rem;
  --fs-l: 1.5rem;
  --fs-m: 1.25rem;
  --fs-s: .95rem;
  --fs-xs: .8rem;
  --anim-timing: 1s;
  --anim-ease: linear;
  --header-height: calc(var(--ui-icon-size)  + (var(--margin-m) * 2));
}

@media screen and (min-width: 375px) {
  :root {
    --ui-icon-size: 3.5rem;
    --fs-m: 1.35rem;
  }
}

@media screen and (min-width: 768px) {
  :root {
    --ui-icon-margin: 1.5rem;
    --ui-icon-size: 3.5rem;
    --fs-xl: 2rem;
    --fs-l: 3rem;
    --fs-m: 1.35rem;
    --fs-s: 1rem;
  }
}

@media screen and (min-width: 992px) {
  :root {
    --fs-xl: 2.75rem;
    --fs-m: 1.25rem;
    --fs-s: 1.15rem;
  }
}

@media screen and (min-width: 1200px) {
  :root {
    --fs-xl: 3.5rem;
    --fs-m: 1.35rem;
    --fs-s: 1.25rem;
  }
}

html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: rgba(0, 0, 0, 0);
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

*, :before, :after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0;
  padding: 0;
}

:focus {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
}

p, a, ul, ol, li, span, strong, button {
  vertical-align: baseline;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
  -ms-font-feature-settings: "kern" 1;
  -o-font-feature-settings: "kern" 1;
  -webkit-font-feature-settings: "kern" 1;
  font-feature-settings: "kern" 1;
  -webkit-font-kerning: normal;
  font-kerning: normal;
}

a, abbr, acronym, address, applet, big, blockquote, body, caption, cite, code, dd, del, dfn, div, dl, dt, em, fieldset, form, h1, h2, h3, h4, h5, h6, html, iframe, img, ins, kbd, label, legend, li, object, ol, p, pre, q, s, samp, small, span, strike, strong, sub, sup, table, tbody, td, tfoot, th, thead, tr, tt, ul, var {
  vertical-align: baseline;
  font-size: 100%;
  font-weight: inherit;
  font-style: inherit;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  font-family: inherit;
}

a, a:hover, a:focus, a:visited {
  text-decoration: none;
}

html {
  font-size: 14px;
}

a {
  color: var(--color-text);
  text-decoration: none;
}

ul {
  list-style-type: none;
}

img, video, audio {
  display: block;
}

body {
  font-family: campus_grotesk, sans-serif;
  font-size: var(--fs-m);
  color: var(--color-text);
  line-height: 1.15;
}

h1, h2, h3, h4, h5, h6, strong {
  font-weight: 400;
}

p a, li a {
  color: var(--color-mediumGrey);
  transition: color .25s;
  display: inline-block;
}

p a:hover, li a:hover {
  color: var(--color-text);
}

.title, .fs-title, .fs-larger {
  font-size: var(--fs-xl);
  line-height: 1.15;
}

.fs-large {
  font-size: var(--fs-l);
  line-height: 1.15;
}

.fs-medium {
  font-size: var(--fs-m);
  line-height: 1.15;
}

.fs-small {
  font-size: var(--fs-s);
  line-height: 1;
}

.fs-smaller {
  font-size: var(--fs-xs);
  line-height: 1;
}

.clearfix:after {
  clear: both;
  content: "";
  display: table;
}

.is-hyphenized {
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.is-hidden {
  visibility: hidden;
  display: none !important;
}

@media screen and (min-width: 768px) {
  .is-hyphenized {
    -webkit-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
  }
}

.nav {
  z-index: var(--zi-nav);
  padding: var(--margin-m);
  width: 100%;
  height: var(--ui-icon-height);
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.nav-wrapper {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.nav-wrapper, .nav-item, .btn-wrapper {
  height: 100%;
}

.nav-item, .nav-faq .bar-wrapper {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.btn-wrapper {
  width: var(--ui-icon-size);
  height: var(--ui-icon-size);
}

.btn-wrapper, .bar-wrapper {
  margin-right: var(--margin-s);
}

.nav-faq .bar-wrapper {
  background-color: var(--color-ui);
  border-radius: var(--ui-radius-l);
  padding: 1px var(--margin-m) 0;
  height: var(--ui-icon-size);
  width: 170px;
  position: relative;
}

.nav-faq .bar-wrapper .btn-wrapper {
  background-color: var(--color-ui);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: var(--ui-radius-l);
  border-bottom-right-radius: var(--ui-radius-l);
  margin: 0 var(--margin-s) 0 0;
  position: absolute;
  top: 0;
  right: 0;
}

.nav-faq .bar-wrapper .js-clear-search .btn--close {
  background-color: var(--color-ui);
}

.nav-faq .bar-wrapper .js-clear-search .btn--close .line {
  background-color: var(--color-primary);
}

.nav-item.nav-info {
  width: var(--ui-icon-size);
  min-width: var(--ui-icon-size);
}

@media screen and (min-width: 375px) {
  .nav-faq .bar-wrapper {
    width: 180px;
  }
}

@media screen and (min-width: 414px) {
  .nav-faq .bar-wrapper {
    width: 190px;
  }
}

@media screen and (min-width: 768px) {
  .nav {
    width: auto;
  }

  .nav-faq .bar-wrapper {
    width: 300px;
  }
}

#main {
  height: 100vh;
  max-height: 100vh;
}

#main.page-home {
  overflow: hidden;
}

.aside {
  background-color: var(--color-clearWhite);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  padding-top: var(--header-height);
  width: 100%;
  height: 100%;
  max-height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

.aside-faq {
  z-index: var(--zi-faq);
  height: 100vh;
}

.aside-info {
  z-index: var(--zi-info);
}

@media screen and (min-width: 768px) {
  .aside-info {
    width: calc(100% - 50px);
    margin-left: 50px;
  }
}

.block {
  display: block;
}

.block-showreel {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: auto;
  max-height: 33.5vh;
  min-height: 33.5vh;
  position: relative;
}

@media screen and (min-width: 768px) {
  .block-showreel {
    max-height: 50vh;
    min-height: 50vh;
  }
}

@media screen and (min-width: 992px) {
  .block-showreel {
    max-height: 100vh;
    min-height: 100vh;
    position: absolute;
  }
}

.block-info {
  text-align: center;
  padding: var(--margin-m) var(--margin-l);
  opacity: 0;
  margin: auto;
  position: relative;
  -webkit-transform: translateY(25px);
  transform: translateY(25px);
}

.block-info a:hover {
  color: var(--color-hover);
}

.block-info p + p {
  padding: var(--margin-m) 0 0;
}

.block-info .title {
  text-transform: uppercase;
  padding: var(--margin-s) var(--margin-xxl);
}

.block--about .title, .block--imprint .title {
  display: none;
}

.block--imprint .content {
  font-size: var(--fs-s);
  line-height: 1;
}

@media screen and (min-width: 768px) {
  .block-info {
    width: 80%;
    padding: 25px 50px;
  }
}

.search-hints {
  -webkit-flex-flow: column wrap;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.hint {
  padding: var(--margin-s);
  display: none;
}

.active-hint {
  display: block;
}

.hint .btn {
  visibility: hidden;
  opacity: 0;
}

@media screen and (min-width: 768px) {
  .search-hints {
    width: 70%;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    margin: auto;
  }
}

.result__body ul {
  text-align: center;
}

.result__body p + p, .result__body ul + p {
  padding: var(--margin-m) 0 0 0;
}

.result__body p + a {
  margin: var(--margin-m) 0;
}

@media screen and (min-width: 768px) {
  .result__body p + p, .result__body ul + p {
    padding: var(--margin-l) 0 0 0;
  }

  .result__body p + a {
    margin: var(--margin-l) 0;
  }
}

.result__body .media .caption {
  text-align: center;
  padding: var(--margin-s) 0 0 0;
  margin: auto;
}

.result__body .media {
  padding: var(--margin-m) 0;
}

.result__body .media + .media {
  padding: 0 0 var(--margin-m) 0;
}

.result__body .media .image {
  border-radius: var(--ui-radius-m);
  width: 100%;
  max-width: 100%;
  max-height: 80vh;
  margin: auto;
}

@media screen and (min-width: 768px) {
  .result__body .media {
    padding: var(--margin-xl) 0;
  }

  .result__body .media + .media {
    padding: 0 0 var(--margin-xl) 0;
  }

  .result__body .media .image {
    width: auto;
  }
}

.result__body .media .video {
  border-radius: var(--ui-radius-m);
  width: auto;
  max-width: 100%;
  max-height: 80vh;
  margin: auto;
}

.result__body .media .audio {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .result__body .media .audio {
    width: 50%;
  }
}

.result__related {
  text-align: center;
  margin-top: var(--margin-xl);
  padding-bottom: var(--margin-m) !important;
}

.related-wrapper {
  -webkit-flex-flow: column wrap;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.related {
  padding: var(--margin-s);
}

@media screen and (min-width: 768px) {
  .result__related {
    width: 70%;
    margin: auto;
    padding: 6vw 0 !important;
  }

  .related-wrapper {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

.result {
  visibility: hidden;
  opacity: 0;
  width: 100%;
  display: none;
  -webkit-transform: translateY(25px);
  transform: translateY(25px);
}

.active-result {
  display: block;
}

.result__body {
  text-align: center;
}

.result__body * {
  margin: auto;
}

.result__body :not(.btn):last-child {
  padding-bottom: 0 !important;
}

.result__body :last-child {
  margin-bottom: 0 !important;
}

.result__body, .result__related {
  padding: var(--margin-m) 0;
}

@media screen and (min-width: 768px) {
  .result__body {
    width: 73%;
    margin: auto;
    padding: 2vw 0 0 !important;
  }
}

.search-null {
  text-align: center;
  padding: 0 0 var(--margin-l) 0;
  width: 100%;
  margin: auto;
  display: none;
}

.active-null {
  display: block;
}

@media screen and (min-width: 768px) {
  .search-null {
    width: 70%;
  }
}

button {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
}

.btn {
  color: var(--color-text);
  cursor: pointer;
  border-radius: 50%;
  display: inline-block;
  position: relative;
}

.btn .btn-inner {
  width: 100%;
  height: 100%;
  position: relative;
}

.btn .line {
  background: var(--color-ui);
  border-radius: var(--ui-radius-l);
  -webkit-transform-origin: center;
  transform-origin: center;
  width: calc(100% - var(--ui-icon-margin));
  height: var(--ui-icon-height);
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%)rotate(0);
  transform: translate(-50%, -50%)rotate(0);
}

.btn .line:nth-child(2) {
  -webkit-transform: translate(-50%, -50%)rotate(-90deg);
  transform: translate(-50%, -50%)rotate(-90deg);
}

.btn--close {
  width: 2rem;
  height: 2rem;
  background-color: var(--color-primary);
}

.nav-faq .btn--close, .nav-info .btn--close {
  width: var(--ui-icon-size);
  height: var(--ui-icon-size);
}

.btn--close .line {
  width: 50%;
  -webkit-transform: translate(-50%, -50%)rotate(45deg);
  transform: translate(-50%, -50%)rotate(45deg);
}

.btn--close .line:last-child {
  -webkit-transform: translate(-50%, -50%)rotate(-45deg);
  transform: translate(-50%, -50%)rotate(-45deg);
}

.btn--info {
  background-color: var(--color-ui);
  width: var(--ui-icon-size);
  height: var(--ui-icon-size);
}

.btn--info .dot {
  -webkit-transform-origin: center;
  transform-origin: center;
  background: var(--color-primary);
  width: 13%;
  height: 13%;
  border-radius: 50%;
  position: absolute;
  top: 28%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.btn--info .line {
  height: var(--ui-icon-height);
  background: var(--color-primary);
  -webkit-transform-origin: center;
  transform-origin: center;
  width: 36%;
  top: 58%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%)rotate(90deg);
  transform: translate(-50%, -50%)rotate(90deg);
}

.btn--search {
  width: var(--ui-icon-size);
  height: var(--ui-icon-size);
}

.btn--search .circles {
  width: 90%;
  height: 90%;
  display: block;
  position: relative;
}

.btn--search .circle {
  -webkit-transform-origin: center;
  transform-origin: center;
  background: var(--color-primary);
  border-radius: 50%;
  position: absolute;
  top: 48%;
  left: 48%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.btn--search .circle--background {
  width: calc(100% - var(--ui-icon-margin) * .8);
  height: calc(100% - var(--ui-icon-margin) * .8);
  z-index: 2;
}

.btn--search .circle--foreground {
  width: calc(100% - var(--ui-icon-margin) * 1.25);
  height: calc(100% - var(--ui-icon-margin) * 1.25);
  background: var(--color-ui);
  z-index: 3;
}

.btn--search .line {
  background: var(--color-primary);
  -webkit-transform-origin: center;
  transform-origin: center;
  width: 30%;
  height: var(--ui-icon-height);
  z-index: 1;
  display: inline-block;
  position: absolute;
  top: 63%;
  bottom: 0;
  left: 63%;
  right: 0;
  -webkit-transform: translate(-50%)rotate(45deg) !important;
  transform: translate(-50%)rotate(45deg) !important;
}

.btn--faq {
  color: var(--color-white);
  border-radius: var(--ui-radius-xl);
  padding: var(--margin-m) var(--margin-xl);
  padding-top: calc(var(--margin-m)  + 1px);
  margin: var(--margin-l) 0;
  line-height: 1;
  position: relative;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -2px rgba(0, 0, 0, .05);
}

@media (hover: hover) {
  .block-answer .btn--faq {
    transition: background-color .5s;
  }

  .block-answer .btn--faq:hover {
    background-color: var(--color-text) !important;
  }
}

@media screen and (min-width: 768px) {
  .btn--faq {
    margin: var(--margin-xl) 0;
  }
}

.btn--hint, .btn--related {
  background-color: var(--color-mediumLightGrey);
  border-radius: var(--ui-radius-xl);
  color: var(--color-text);
  padding: var(--margin-m) var(--margin-xl);
  padding-top: calc(var(--margin-m)  + 2px);
  cursor: pointer;
  line-height: 1;
  transition-property: background-color, color;
  transition-duration: .25s, .2s;
  transition-timing-function: ease;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -2px rgba(0, 0, 0, .05);
}

@media (hover: hover) {
  .btn--hint:hover, .btn--related:hover {
    background-color: var(--color-text);
    color: var(--color-mediumLightGrey);
  }
}

input[type="search"]::-ms-clear {
  width: 0;
  height: 0;
  display: none;
}

input[type="search"]::-ms-reveal {
  width: 0;
  height: 0;
  display: none;
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
}

.block-faq-intro {
  display: none;
}

.block-faq-intro.is-active {
  display: block;
}

.block-faq {
  display: none;
}

.block-faq.is-active {
  display: block;
}

#no-result {
  display: none;
}

#no-result.is-active {
  display: block;
}

mark {
  background-color: var(--color-text);
  color: var(--color-white);
}

.search-bar {
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: var(--ui-radius-m);
  height: var(--ui-icon-size);
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  border: 0 solid rgba(0, 0, 0, 0);
  font-family: inherit;
  overflow: hidden;
}

.plyr {
  --plyr-color-main: var(--color-primary);
  border-radius: var(--ui-radius-m);
}

.plyr__menu {
  display: none;
}

.plyr__progress input[type="range"], .plyr__progress__buffer {
  width: 100%;
}

.plyr__progress__buffer {
  display: none;
}

.plyr--audio .plyr__control:focus-visible, .plyr--audio .plyr__control:hover, .plyr--audio .plyr__control[aria-expanded="true"] {
  background: inherit;
  color: inherit;
}

.view-alerts {
  padding: var(--margin-m);
  z-index: var(--zi-alerts);
  width: 100%;
  max-height: calc(100vh - var(--header-height));
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-bottom: 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  top: auto;
  bottom: 0;
  left: auto;
  right: 0;
  overflow-y: scroll;
}

.view-alerts.has-overflow {
  -webkit-mask-image: linear-gradient(rgba(255, 255, 255, 0) 2%, #000 6%, #000 10%);
  mask-image: linear-gradient(rgba(255, 255, 255, 0) 2%, #000 6%, #000 10%);
}

.view-alerts::-webkit-scrollbar {
  display: none;
}

.view-alerts {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.block-alert {
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
  margin: 0;
}

.alert-inner {
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
  padding: 0 0 1rem;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.bubble {
  width: auto;
  max-width: 200px;
  background: var(--color-ui);
  border-top-left-radius: var(--ui-radius-m);
  border-top-right-radius: var(--ui-radius-m);
  border-bottom-left-radius: var(--ui-radius-m);
  padding: var(--margin-m);
  border-bottom-right-radius: 0;
  position: relative;
}

.block-alert .btn-wrapper {
  width: 2rem;
  height: 2rem;
  margin: -.8rem 0 0 -.9rem;
  position: absolute;
  top: 0;
  left: 0;
}

.block-alert .icon {
  width: 3rem;
  height: 3rem;
  margin-left: 1rem;
}

.block-alert .icon .image {
  width: inherit;
  height: inherit;
  border-radius: 50%;
  display: block;
}

@media screen and (min-width: 375px) {
  .bubble {
    max-width: 250px;
  }
}

@media screen and (min-width: 768px) {
  .view-alerts {
    width: 50%;
  }
}

@media screen and (min-width: 992px) {
  .view-alerts {
    -webkit-transform: unset;
    transform: unset;
    -webkit-mask-image: unset;
    mask-image: unset;
    width: 25%;
    max-width: auto;
    height: 100%;
    max-height: 100vh;
    padding: 0;
    top: auto;
    bottom: 0;
    left: auto;
    overflow-y: scroll;
  }

  .block-alert {
    padding: var(--margin-m) var(--margin-m) 0 var(--margin-m);
  }

  .block-alert:last-child {
    padding: var(--margin-m);
  }

  .alert-inner {
    padding: 0;
  }

  .block-alert .icon {
    width: 3.25vw;
    height: 3.25vw;
    margin-left: 1rem;
  }
}

@media screen and (min-width: 1200px) {
  .view-alerts {
    width: 20%;
  }

  .block-alert .icon {
    width: 2.25vw;
    height: 2.25vw;
  }
}

#main.page-faq {
  height: 100%;
  max-height: unset;
}

.aside > #main.page-faq {
  background-color: var(--color-clearWhite);
  background-color: rgba(0, 0, 0, 0);
}

.page-http404 {
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.page-http404 .content {
  text-align: center;
}

#showreel {
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

@media screen and (min-width: 992px) {
  #showreel {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  #showreel .block-showreel:first-child, #showreel .block-showreel:nth-child(2) {
    display: none;
  }
}

.view-faq {
  padding: 0 var(--margin-m);
  padding-bottom: var(--margin-m);
  height: auto;
  margin: auto;
  position: relative;
}

/*# sourceMappingURL=main.css.map */
