/**
 *
 *  This file contains only basic styles.
 *
 *
 */

html {
    font-size: 14px;
}

a {
    color: var(--color-text);
    text-decoration: none;
}

ul {
    list-style-type: none;
}

img,
video,
audio {
    display: block;
}
