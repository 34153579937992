.block-info {
	position: relative;
	text-align: center;
	transform: translateY(25);
	padding: var(--margin-m) var(--margin-l);
	margin: auto;
	opacity: 0;
	// width: 80%;
}

// .block-info a {
// 	color: pink;
// }

.block-info a:hover {
	color: var(--color-hover);
}

.block-info p + p {
	padding: var(--margin-m) 0 0;
}

// .block-info ul {
// 	list-style-type: disclosure-closed;
//     list-style-position: inside;
// }

.block-info .title {
	text-transform: uppercase;
	padding: var(--margin-s) var(--margin-xxl);
}

.block--about .title,
.block--imprint .title {
	display: none;
}

// size fs-small
.block--imprint .content {
	font-size: var(--fs-s);
	line-height: 1;
}


@media screen and (min-width: $md-m) {
	.block-info {
		padding: 25px 50px;
		width: 80%;
	}

	// .block-info ul {
	// 	list-style-type: none;
	// }
}