*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0;
  padding: 0;
}

*:focus {
  background-color: transparent;
  outline: none;
  border: none;
}

p,
a,
ul,
ol,
li,
span,
strong,
button {
  vertical-align: baseline;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
  -ms-font-feature-settings: "kern"1;
  -o-font-feature-settings: "kern"1;
  -webkit-font-feature-settings: "kern"1;
  font-feature-settings: "kern"1;
  -webkit-font-kerning: normal;
  font-kerning: normal;
}

a,
abbr,
acronym,
address,
applet,
big,
blockquote,
body,
caption,
cite,
code,
dd,
del,
dfn,
div,
dl,
dt,
em,
fieldset,
form,
h1,
h2,
h3,
h4,
h5,
h6,
html,
iframe,
img,
ins,
kbd,
label,
legend,
li,
object,
ol,
p,
pre,
q,
s,
samp,
small,
span,
strike,
strong,
sub,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
tr,
tt,
ul,
var {
  font-size: 100%;
  vertical-align: baseline;
  font-weight: inherit;
  font-style: inherit;
  font-family: inherit;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

a,
a:hover,
a:focus,
a:visited {
  text-decoration: none;
}
