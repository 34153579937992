.search-hints {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
}

.hint {
	display: none;
	padding: var(--margin-s);
}

.active-hint {
	display: block;
}

.hint .btn {
	visibility: hidden;
	opacity: 0;
}

@media screen and (min-width: $md-m) {
	.search-hints {
		flex-direction: row;
		margin: auto;
		width: 70%;
	}
}