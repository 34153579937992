/**
 *
 *  d-e-a-l
 *  v3.1.0 
 *  2023.04.13
 *  d-e-a-l.eu
 *  hello@d-e-al.eu
 *
 *
 */

@charset "UTF-8";

// Configuration
@import 'abstracts/variables';
@import 'abstracts/mixins';
@import 'vendors/normalize';

// Base
@import 'base/reset';
@import 'base/base';
@import 'base/fonts';
@import 'base/typography';
@import 'base/helpers';

// 4. Layout
@import 'layout/nav';
@import 'layout/main';
@import 'layout/aside';

// Components
@import 'components/block';
@import 'components/block-showreel';
@import 'components/block-info';
@import 'components/block-faq';
// @import 'components/block-faq-intro';
// @import 'components/block-faq-no-result';
@import 'components/btn';
@import 'components/btn-close';
@import 'components/btn-info';
@import 'components/btn-search';
@import 'components/btn-faq';
@import 'components/btn-hint';
@import 'components/search';
@import 'components/search-bar';
@import 'components/plyr';
@import 'components/alert';
// @import 'components/htmx-animations';

// Pages
@import 'pages/home';
@import 'pages/faq';
@import 'pages/404';

// Views
@import 'views/showreel';
@import 'views/info';
@import 'views/faq';

// Themes
@import 'themes/default';
