.result__body .media {
	padding: var(--margin-m) 0;
}

.result__body .media + .media {
	padding: 0 0 var(--margin-m) 0;
}

.result__body .media .image {
	border-radius: var(--ui-radius-m);
	margin: auto;
	width: 100%;
	max-width: 100%;
	max-height: 80vh;
}

@media screen and (min-width: $md-m) {
	.result__body .media {
		padding: var(--margin-xl) 0;
	}
	
	.result__body .media + .media {
		padding: 0 0 var(--margin-xl) 0;
	}

	.result__body .media .image {
		width: auto;
	}
}