.btn--close {
    width: 2rem;
    height: 2rem;
    background-color: var(--color-primary);
}

.nav-faq .btn--close,
.nav-info .btn--close {
    width: var(--ui-icon-size);
    height: var(--ui-icon-size);
	// margin-right: var(--margin-s);
}

.btn--close .line {
    width: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
}

.btn--close .line:last-child {
    transform: translate(-50%, -50%) rotate(-45deg);
}