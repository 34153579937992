// .result__body h1 {
// 	font-size: var(--fs-l);
// 	font-weight: normal;
// 	line-height: 1.15;
// }

// .result__body h2 {
// 	font-size: var(--fs-l);
// 	font-weight: normal;
// 	line-height: 1.15;
// }

// .result__body h3 {
// 	font-size: var(--fs-m);
// 	font-weight: normal;
// 	line-height: 1.15;
// }

// .result__body h4 {
// 	font-size: var(--fs-s);
// 	font-weight: normal;
// 	line-height: 1;
// }

.result__body ul {
	text-align: center;
}

.result__body p + p,
.result__body ul + p  {
	padding: var(--margin-m) 0 0 0;
}

.result__body p + a {
	margin: var(--margin-m) 0;
}

// .result__body a:not(.btn) {
// 	color: pink;
// }

@media screen and (min-width: $md-m) {
	.result__body p + p,
	.result__body ul + p  {
		padding: var(--margin-l) 0 0 0;
	}

	.result__body p + a {
		margin: var(--margin-l) 0;
	}
}