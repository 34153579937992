.btn--info {
	background-color: var(--color-ui);
	width: var(--ui-icon-size);
    height: var(--ui-icon-size);
}

.btn--info .dot {
	position: absolute;
    transform-origin: center;
    background: var(--color-primary);
    transform: translate(-50%, -50%);
    border-radius: 50%;
    width: 13%;
    height: 13%;
    top: 28%;
    left: 50%;
}

.btn--info .line {
	height: var(--ui-icon-height);
    background: var(--color-primary);
    transform: translate(-50%, -50%) rotate(90deg);
    transform-origin: center;
    width: 36%;
    top: 58%;
    left: 50%;
}