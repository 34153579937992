.view-alerts {
	display: flex;
	flex-direction: column;
    position: fixed;
    padding: var(--margin-m);
	padding-bottom: 0;
    z-index: var(--zi-alerts);
	// width: 80%;
    // max-width: 80%;
	width: 100%;
	max-height: calc(100vh - var(--header-height));
    overflow-y: scroll;
    top: auto;
	left: auto;
    right: 0;
	bottom: 0;
}

.view-alerts.has-overflow {
	mask-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 2%, #000 6%, #000 10%);
}

.view-alerts::-webkit-scrollbar {
    display: none;
}

.view-alerts {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.block-alert {
	transform-origin: top right;
	margin: 0;
}

.alert-inner {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
	transform-origin: top right;
	padding: 0 0 1rem;
}

.bubble {
    width: auto;
	// max-width: 180px;
	max-width: 200px;
    position: relative;
    background: var(--color-ui);
    border-top-left-radius: var(--ui-radius-m);
    border-top-right-radius: var(--ui-radius-m);
    border-bottom-left-radius: var(--ui-radius-m);
    border-bottom-right-radius: 0;
    padding: var(--margin-m);
}

.block-alert .btn-wrapper {
	position: absolute;
	margin: -0.8rem 0 0 -0.9rem;
	width: 2rem;
    height: 2rem;
    top: 0;
    left: 0;
}

.block-alert .icon {
    width: 3rem;
    height: 3rem;
    margin-left: 1rem;
}

.block-alert .icon .image {
    display: block;
	border-radius: 50%;
    width: inherit;
    height: inherit;
}

@media screen and (min-width: $md-xs) {
	.bubble {
		max-width: 250px;
	}
}

@media screen and (min-width: $md-m) {
	.view-alerts {
		width: 50%;
    }

	// .bubble {
	// 	max-width: 200px;
	// }
}

@media screen and (min-width: $md-l) {
    .view-alerts {
		transform: unset;
		mask-image: unset;
		overflow-y: scroll;
		padding: 0;
		width: 25%;
        max-width: auto;
		height: 100%;
		max-height: 100vh;
		top: auto;
		left: auto;
		bottom: 0;
    }

    .block-alert {
        padding: var(--margin-m) var(--margin-m) 0 var(--margin-m);
    }

	.block-alert:last-child {
		padding: var(--margin-m);
	}

	.alert-inner {
		padding: 0;
	}

    .block-alert .icon {
        width: 3.25vw;
        height: 3.25vw;
        margin-left: 1rem;
    }
}

@media screen and (min-width: $md-xl) {
	.view-alerts {
		width: 20%;
    }
	
	.block-alert .icon {
        width: 2.25vw;
        height: 2.25vw;
    }
}