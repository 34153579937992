@import 'faq-text';
@import 'faq-caption';
@import 'faq-image';
@import 'faq-video';
@import 'faq-audio';
@import 'faq-related';

.result {
	display: none;
	transform: translateY(25);
	visibility: hidden;
	opacity: 0;
	width: 100%;
}

.active-result {
	display: block;
}

.result__body {
	text-align: center;
}

.result__body * {
	margin: auto;
}

.result__body *:not(.btn):last-child {
	padding-bottom: 0 !important;
}

.result__body *:last-child {
	margin-bottom: 0 !important;
}

.result__body,
.result__related {
	padding: var(--margin-m) 0;
}

@media screen and (min-width: $md-m) {
	.result__body {
		width: 73%;
		margin: auto;
		padding: 2vw 0 0 0 !important;
	}

	// .result__body,
	// .result__related {
	// 	padding: var(--margin-m) 0;
	// }
}
