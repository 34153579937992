#showreel {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media screen and (min-width: $md-l) {
    #showreel {
        flex-direction: row;
    }

    #showreel .block-showreel {
        &:nth-child(1),
        &:nth-child(2) {
            display: none;
        }
    }
}