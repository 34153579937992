.search-bar {
	font-family: inherit;
	overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
	background-color: transparent;
    border: 0px solid transparent;
    border-radius: var(--ui-radius-m);
	height: var(--ui-icon-size);
	width: 100%;
}
