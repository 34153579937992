/* clears the ‘X’ from Internet Explorer */
input[type='search']::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}
input[type='search']::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

/* clears the ‘X’ from Chrome */
[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
    appearance: none;
}

// custom
.block-faq-intro {
    display: none;
}

.block-faq-intro.is-active {
    display: block;
}

.block-faq {
    display: none;
}

.block-faq.is-active {
    display: block;
}

#no-result {
    display: none;
}

#no-result.is-active {
    display: block;
}

mark {
    background-color: var(--color-text);
    color: var(--color-white);
}
