/**
 *
 *  This file contains all @font-face declarations.
 *
 *
 */

/* @font-face {
  font-family: "fontName";
  src: url("../fonts/fontName.eot");
  src: url("../fonts/fontName.eot?#iefix") format("embedded-opentype"),
    url("../fonts/fontName.woff") format("woff"),
    url("../fonts/fontName.ttf") format("truetype"),
    url("../fonts/fontName.svg#fontName") format("svg");
  font-weight: 400;
  font-style: normal;
} */
