.aside {
    position: fixed;
	background-color: var(--color-clearWhite);
	backdrop-filter: blur(10px);
	padding-top: var(--header-height);
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    top: 0;	
    bottom: 0;
    left: 0;
    right: 0;
}

.aside-faq {
	// background-color: var(--color-clearWhite);
	// backdrop-filter: blur(10px);
	// padding-top: var(--header-height);
	z-index: var(--zi-faq);
	height: 100vh;
}

.aside-info {
	z-index: var(--zi-info);
}

@media screen and (min-width: $md-m) {
	.aside-info {
		margin-left: 50px;
		width: calc(100% - 50px);
	}
}