.block-showreel {
	position: relative;
	object-fit: cover;
	object-position: center;
	width: 100%;
	height: auto;
	max-height: 33.5vh;
	min-height: 33.5vh;
}

@media screen and (min-width: $md-m) {
    .block-showreel {
		max-height: 50vh;
		min-height: 50vh;
	}
}

@media screen and (min-width: $md-l) {
    .block-showreel {
		position: absolute;
		max-height: 100vh;
		min-height: 100vh;
	}
}