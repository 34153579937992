/**
 *
 *  This file contains typography specific classes and systems.
 *
 *
 */

body {
	// font-family: 'helvetica_52', sans-serif;
	font-family: 'campus_grotesk', sans-serif;
	// font-weight: 600 !important;
	font-size: var(--fs-m);
	line-height: 1.15;
	color: var(--color-text);
}

h1,
h2,
h3,
h4,
h5,
h6,
strong {
	// font-weight: bold;
	// font-weight: 600;
	font-weight: normal;
	font-weight: 400;
}

p a,
li a {
	display: inline-block;
	color: var(--color-mediumGrey);
	transition: color 0.25s ease;
}

p a:hover,
li a:hover {
	color: var(--color-text);
}

.title,
.fs-title,
.fs-larger {
	font-size: var(--fs-xl);
	line-height: 1.15;
}

.fs-large {
	font-size: var(--fs-l);
	line-height: 1.15;
}

.fs-medium {
	font-size: var(--fs-m);
	line-height: 1.15;
}

.fs-small {
	font-size: var(--fs-s);
	line-height: 1;
}

.fs-smaller {
	font-size: var(--fs-xs);
	line-height: 1;
}
