.btn--faq {
	line-height: 1;
	position: relative;
	color: var(--color-white);
	border-radius: var(--ui-radius-xl);
	box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
	padding: var(--margin-m) var(--margin-xl);
	padding-top: calc(var(--margin-m) + 1px);
	margin: var(--margin-l) 0;
    // transform: translateX(-50%);
    // left: 50%;
}

@media (hover: hover) {
	.block-answer .btn--faq {
		transition: background-color .5s ease;	
	}

	.block-answer .btn--faq:hover {
		background-color: var(--color-text) !important;
	}
}

@media screen and (min-width: $md-m) {
    .btn--faq {
		margin: var(--margin-xl) 0;
	}
}