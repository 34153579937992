.search-null {
	display: none;
	text-align: center;
	padding: 0 0 var(--margin-l) 0;
	margin: auto;
	width: 100%;
}

.active-null {
	display: block;
}

@media screen and (min-width: $md-m) {
	.search-null {
		width: 70%;
	}
}